/* eslint-disable perfectionist/sort-imports */
import { createApp, type DefineComponent, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import dayjs from 'dayjs';
import de from 'dayjs/locale/de';
import localeData from 'dayjs/plugin/localeData';

import '@unocss/reset/tailwind.css';
import '../css/main.css';
import 'uno.css';

// @ts-expect-error: no types
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

import { createHead } from '@unhead/vue';

const head = createHead();

void createInertiaApp({
  progress: {
    color: '#4B5563'
  },
  resolve: name => resolvePageComponent(`./pages/${name}.vue`, import.meta.glob<DefineComponent>('./pages/**/*.vue')),
  setup({ App, el, plugin, props }) {
    const app = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(ZiggyVue, Ziggy)
      .use(head)
      .mount(el);

    // eslint-disable-next-line ts/no-unsafe-member-access
    delete el.dataset.page;

    return app;
  }
});

dayjs.locale(de);
dayjs.extend(localeData);
